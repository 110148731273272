import React, { PureComponent,createRef } from 'react';
import { connect } from 'dva';
import {
  Button,
  Modal,
  Icon,
  Select,
  message,
  Table,
  InputNumber,
  Popover,
  Alert,
  Tooltip,
  Drawer,
  Checkbox,
  Menu,
  Dropdown
} from 'antd';

import className from 'classnames';
import intl from 'react-intl-universal';
import round from 'lodash/round';

import { fixedZero, getAliExpressUrl, replaceUrlToHttps } from '../../utils/utils';
import { currencySymbolMap } from '../../common/sysconfig';
import { getMsgByType, getShopifyProdItems } from './utils';
import { InfoAlert } from '../../components//Banner/OptimizerInfoTip';

import styles from './PriceChange.less';

import upArrow from '../../assets/notification/shang.png';
import downArrow from '../../assets/notification/xia.png';
import foreignLink from '../../assets/foreign.svg';
import copy from 'assets/bargain/copy.png';
import checked from 'assets/bargain/check_circle.png';
import copyToClipboard from 'utils/copyToClipboard';
import moment from 'moment-timezone';

const { Option } = Select;

/* eslint react/no-array-index-key: 0 */
@connect(({ notification, loading, login, global }) => ({
  notification,
  userInfo: login.userInfo,
  storeCurrency: global.storeCurrency,
  loading: loading.models.notification,
  timezoneRegion: global.timezoneRegion,
  listLoading: loading.effects['notification/list'],
  detailLoading: loading.effects['notification/shopifyProdDetail'],
  updateLoading: loading.effects['notification/updateShopifyPrice'],
  customizeCurrency: login.customizeCurrency
}))
export default class PriceChange extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      toolSelectValue: 1, // 批量修改价格中select的值,
      productid: '',
      dropdownVisible: false,
      monetizationVisible: false,
      selectedRowKeys: [],
      copySuccess: '',
      supplyType: ''
    };
    this.ref = React.createRef();
  }
  componentWillReceiveProps(nextProps) {
    const { visible, notificationItem } = nextProps;

    visible &&
      this.hasGetShopifyProdDetail &&
      this.getShopifyProdDetail(
        getShopifyProdItems(notificationItem) &&
          getShopifyProdItems(notificationItem)[0]?.productid,
        nextProps
      );
  }

  hasGetShopifyProdDetail = true; // 显示弹窗时有没有获取过商品详情
  shopifyProductDetail = null; // shopify product 商品详情
  changeWho = ''; // 批量修改谁的价格 price || compare at price
  changeAllValue = 0; // 批量修改输入的price值
  changeCompareValue = 0; // 批量修改输入的compare price值
  hasChangeMapping = true; // shopify商品有没有更换过mapping商品 导致现存的ae商品消息数据 和 shopify商品mapping数据对不上
  storeHasRemoved = false;
  storeId = '';
  savePriceNumber = {
    variantID: '',
    number: '',
    flag: ''
  };
  shopifyDetailError = '';

  // 获取shopify商品详情
  getShopifyProdDetail = (id, nextProps) => {
    this.storeId = this.getStoreId(id, nextProps);

    this.setState({
      productid: id
    });

    if (!this.storesHasStoreId(this.storeId)) {
      this.storeHasRemoved = true;
      return;
    }

    this.hasGetShopifyProdDetail = false;
    this.hasChangeMapping = true;
    this.shopifyDetailError = '';
    this.props.dispatch({
      type: 'notification/shopifyProdDetail',
      payload: {
        data: {
          id,
          store_id: this.storeId,
          notify_id: nextProps.notificationItem.id
        },
        cancelMessage: true,
        callback: d => {
          if (d.code == 2000) {
            if (d.data == '') {
              this.hasChangeMapping = false;
            }
            this.shopifyProductDetail = d.data;
            if (d.data?.prod?.ext?.map_type === 1) {
              if (
                d.data.prod.ext.basic[
                  this.props.notificationItem?.data?.aliexpressprod?.productid
                ]?.is_default
              ) {
                this.setState({
                  supplyType: intl.get('notification.defaultSupplier')
                });
              } else {
                this.setState({
                  supplyType: intl.get('notification.secondSupplier')
                });
              }
            } else {
              this.setState({
                supplyType: ''
              });
            }
            this.setChangeDate();
            return;
          } else if (d.code === 5000 && d.msg) {
            this.shopifyDetailError = d.msg;
          }
          this.hasChangeMapping = false;
        }
      }
    });
  };

  // 用户的shopify店铺里有没有这个店铺
  storesHasStoreId = id => {
    const { stores = [] } = this.props.userInfo;

    return stores.some(store => store.id == id);
  };

  // 获取shopify商品所在的store id
  getStoreId = (shopifyPordid, props) => {
    const {
      notificationItem: { shopify_prods = {} }
    } = props;
    let v = '';

    Object.entries(shopify_prods).forEach(arr => {
      arr[1].forEach(item => {
        if (item.productid == shopifyPordid) {
          v = arr[0];
        }
      });
    });

    return v;
  };

  menu = () => {
    const { toolSelectValue } = this.state;
    return (
      <div className={styles.contMenu}>
        <Select
          value={toolSelectValue}
          placeholder="Set New Value"
          onChange={this.changeToolSelect}
          suffixIcon={
            <img
              className="select_icon"
              src={require('../../assets/newImportList/icon_expand_more-24px.png')}
            />
          }
          getPopupContainer={triggerNode => triggerNode.parentNode}
        >
          <Option key="s" value={1}>
            <span className={styles.contSpan}>
              {intl.get('notification.set_new_value')}
            </span>
          </Option>
          <Option key="m" value={2}>
            <span className={styles.contSpan}>
              {intl.get('notification.multiply_by')}
            </span>
          </Option>
        </Select>
        <div className={styles.priceBot}>
          <InputNumber
            placeholder={
              toolSelectValue == 1
                ? intl.get('notification.enter_value')
                : intl.get('notification.enter_multiplier')
            }
            onChange={
              this.changeWho == 'price'
                ? this.handleChangeAllValue
                : this.handleChangeCompareValue
            }
          />
          <Button
            className={styles.priceApply}
            onClick={
              this.changeWho == 'price'
                ? this.changePrice
                : this.changeComparePrice
            }
          >
            {intl.get('public.apply')}
          </Button>
        </div>
      </div>
    );
  };

  // 设置列表数据
  setChangeDate = () => {
    if (this.shopifyProductDetail == '') {
      this.setState({
        list: []
      });
      return;
    }

    const {
      prod: {
        detail: { variants }
      },
      variants: outVariants = []
    } = this.shopifyProductDetail;

    const arr = [];
    const idArr = [];

    variants.forEach(item => {
      const costarr = this.getCostArr(item.id);

      if (costarr.length == 0) {
        return;
      }

      arr.push({
        variantTitle: item.title,
        cost: costarr,
        price: item.price,
        compareAtPrice: item.compare_at_price || 0,
        variantID: item.id
      });

      idArr.push(item.id);
    });
    //获取shopifysell价格 外层variants
    outVariants.forEach((item, index) => {
      const costarr = this.getCostArr(item.id);

      if (costarr.length == 0) {
        return;
      }
      if (arr[index]) {
        arr[index].shopifySellPrice = item.price;
      }
    });

    this.setState({
      list: arr,
      selectedRowKeys: idArr
    });
  };

  // 获取shopify单个variant 对应的ae商品的sku数组
  getCostArr = variantId => {
    const arr = [];
    const skuIDs = this.getAeSkuID(variantId);

    skuIDs.forEach(sku => {
      const obj = {
        sku_desc: sku.sku_desc,
        sku: sku.sku,
        preCost: this.getCost(sku.sku, 'preprod'),
        newCost: this.getCost(sku.sku, 'newprod')
      };

      arr.push(obj);
    });

    return arr;
  };

  // 获取变换前后的价格
  getCost = (skuID, flag) => {
    // flag = preprod || newprod
    const {
      notificationItem: { data = {} }
    } = this.props;

    const prod = data[flag] || {};

    const { aeop_ae_product_s_k_us = {}, item_offer_site_sale_price } = prod;

    let price = 0;

    if (aeop_ae_product_s_k_us && aeop_ae_product_s_k_us.aeop_ae_product_sku) {
      aeop_ae_product_s_k_us.aeop_ae_product_sku.forEach(item => {
        if (item.id == skuID) {
          price =
            item.offer_sale_price ||
            item_offer_site_sale_price ||
            item.sku_price;
        }
      });
    }

    return price * 1;
  };

  // 获取shopify product 某个 variant 对应的ae商品sku id
  getAeSkuID = variantId => {
    const {
      prod: {
        ext,
        ext: { map_type }
      }
    } = this.shopifyProductDetail;

    const {
      notificationItem: {
        data: {
          aliexpressprod: { productid }
        }
      }
    } = this.props;

    const mappingMap = {
      1: 'basic',
      2: 'advance',
      3: 'bogo',
      4: 'bundle',
      5: 'standard'
    };

    const map = ext[mappingMap[map_type]];
    const skuIDs = []; // null

    if (map_type == 1) {
      // const { supply = [] } = map[productid];
      const supply = (map[productid] && map[productid]?.supply) || [];

      // const supply = map[productid]?.supply ?? []

      supply.forEach(item => {
        if (item.variant_id == variantId) {
          item.platform_product_id &&
            item.sku &&
            skuIDs.push({
              sku: item.sku
            });
        }
      });
    } else {
      const variantSp = map && map[variantId];
      variantSp &&
        variantSp.variant_supply.forEach(({ supply }) => {
          supply.forEach(item => {
            item.platform_product_id == productid &&
              item.sku &&
              skuIDs.push({
                sku_desc: item.sku_desc,
                sku: item.sku
              });
          });
        });
    }

    return skuIDs;
  };

  // 获取年月日时分
  timestampToTime = createdat => {
    const dateTime = new Date(createdat);
    dateTime.setMinutes(dateTime.getMinutes() - dateTime.getTimezoneOffset());

    const Y = dateTime.getFullYear();
    const M = fixedZero(dateTime.getMonth() + 1);
    const D = dateTime.getDate();
    const h = fixedZero(dateTime.getHours());
    const m = fixedZero(dateTime.getMinutes());

    return `${Y}-${M}-${D} ${h}:${m}`;
  };

  toJSONLocal = (date, isDate) => {
    const { timezoneRegion } = this.props;
    let local = moment(date);
    if (timezoneRegion) {
      local = moment(date).tz(timezoneRegion);
    }
    if (isDate) {
      return local.format('YYYY-MM-DD HH:mm');
    }
    return local.format('HH:mm');
  };

  // 查看影响的另一个shopify商品
  changeShopifyProd = prodId => {
    this.setState({
      productid: prodId
    });

    this.getShopifyProdDetail(prodId, this.props);
  };

  // 获取shopify商品链接
  getShopifyProdLink = prodId => {
    let stores = [];
    if (localStorage.getItem('storeStatus')) {
      stores = JSON.parse(localStorage.getItem('storeStatus'));
    }

    let domain = '';
    let storeName = '';
    stores.forEach(item => {
      if (item.id == this.storeId) {
        domain = item.domain;
        storeName = item.shop_name;
      }
    });

    domain = domain.includes('myshopify.com')
      ? domain
      : `${storeName}.myshopify.com`;

    return `https://${domain}/admin/products/${prodId}`;
  };

  changeToolSelect = v => {
    this.setState({
      toolSelectValue: v
    });
  };

  // 批量修改输入的price值
  handleChangeAllValue = v => {
    this.changeAllValue = v;
  };
  // 批量修改输入的compare price值
  handleChangeCompareValue = v => {
    this.changeCompareValue = v;
  };

  // 批量改变price
  changePrice = () => {
    const { list, toolSelectValue, selectedRowKeys } = this.state;
    const copyList = [...list];
    let selectRow = [];

    copyList.forEach(item => {
      let v = 0;
      if (selectedRowKeys.includes(item.variantID)) {
        if (toolSelectValue == 1) {
          v = this.changeAllValue.toFixed(2);
        } else {
          v = (item.price * this.changeAllValue).toFixed(2);
        }
      } else {
        v = item.price;
      }

      item[this.changeWho] = v;
      // selectRow.push(item.variantID)
    });

    this.setState({
      list: copyList
      // selectedRowKeys: selectRow
    });
  };

  // 批量改变compare price
  changeComparePrice = () => {
    const { list, toolSelectValue, selectedRowKeys } = this.state;
    const copyList = [...list];
    let selectRow = [];

    copyList.forEach(item => {
      let v = 0;
      if (selectedRowKeys.includes(item.variantID)) {
        if (toolSelectValue == 1) {
          v = this.changeCompareValue.toFixed(2);
        } else {
          v = (item.compareAtPrice * this.changeCompareValue).toFixed(2);
        }
      } else {
        v = item.compareAtPrice;
      }

      item[this.changeWho] = v;
      // selectRow.push(item.variantID)
    });

    this.setState({
      list: copyList
      // selectedRowKeys: selectRow
    });
  };

  // 改变单个variant的价格
  // changeSinglePrice = (v, flag, variantID) => {
  //   this.savePriceNumber = {
  //     variantID: variantID,
  //     number: v,
  //     flag: flag
  //   };
  // };

  // 批量修改弹窗里的内容
  popoverContent = () => {
    const { toolSelectValue } = this.state;

    return (
      <div className={styles.changeTool}>
        <Select
          value={toolSelectValue}
          className={styles.changeSelect}
          onChange={this.changeToolSelect}
          getPopupContainer={triggerNode => triggerNode.parentNode}
        >
          <Option key="s" value={1}>
            {intl.get('notification.multiply_by')}
          </Option>
          <Option key="m" value={2}>
            {intl.get('notification.set_new_value')}
          </Option>
        </Select>
        <br />
        <InputNumber
          className={styles.changeValue}
          placeholder={
            toolSelectValue == 1
              ? intl.get('notification.enter_value')
              : intl.get('notification.enter_multiplier')
          }
          onChange={this.handleChangeCompareValue}
        />
        <div className={styles.changeApply}>
          <Button onClick={this.changeComparePrice}>
            {intl.get('public.apply')}
          </Button>
        </div>
      </div>
    );
  };

  // 鼠标经过 compare_at_price 下的change all
  mouseEnterCompareAtPrice = () => {
    this.changeWho = 'compareAtPrice';
  };

  // 鼠标经过 price 下的change all
  mouseEnterPrice = () => {
    this.changeWho = 'price';
  };

  // 比较价格变化的差价
  getComparePrice = ({ preCost, newCost }) => {
    return (newCost - preCost).toFixed(2);
  };

  // handleMenuClick = e => {
  //   if (e.key === '3') {
  //     this.setState({ visible: false });
  //   }
  // };

  handleVisibleChange = flag => {
    this.setState({ dropdownVisible: flag });
  };

  getColumns = () => {
    const { storeCurrency } = this.props;
    let currencyCode;
    let currencySymbol;
    let isNotUSD;
    let currencyRates;
    const isCustomizeCurrency =
      this.props?.customizeCurrency?.storeId ===
        this.shopifyProductDetail?.prod.store_id &&
      this.props?.customizeCurrency?.id ===
        this.shopifyProductDetail?.prod.user_id;

    if (storeCurrency[this.storeId]) {
      currencyCode = storeCurrency[this.storeId].currency_code || 'USD';
      currencySymbol = currencySymbolMap[currencyCode];
      isNotUSD = currencyCode !== 'USD';
      currencyRates = storeCurrency[this.storeId].rates_value;
    }
    if (isCustomizeCurrency) {
      currencyRates = this.props?.customizeCurrency?.currency;
    }

    return [
      {
        title: intl.get('notification.sku'),
        dataIndex: 'variantTitle',
        align: 'center',
        render: v => {
          return <span className={styles.skuText}>{v}</span>;
        }
      },
      {
        title: intl.get('notification.const_change'),
        dataIndex: 'age',
        align: 'center',
        render: (v, record) =>
          record.cost.map(item => {
            const comparePrice = this.getComparePrice(item);
            return (
              <div key={item.sku} className={styles.costLine}>
                <div className={styles.skuDec}>{item.sku_desc || null}</div>
                <div>
                  <p className={styles.costChangeLine}>
                    <del>
                      <span>{`$${item.preCost}`}</span>
                    </del>
                    <span
                      className={className(styles.costChange, {
                        [styles.costUp]: comparePrice > 0,
                        [styles.costDown]: comparePrice < 0
                      })}
                    >
                      ({` $${comparePrice}`})
                      {comparePrice > 0 ? (
                        <i class="material-icons">trending_up</i>
                      ) : (
                        <i class="material-icons">trending_down</i>
                      )}
                    </span>

                    <span>{`$${item.newCost}`}</span>
                  </p>
                  {isNotUSD ? (
                    <p
                      className={className(
                        styles.costChangeLine,
                        styles.costCurrencyLine
                      )}
                    >
                      <del>
                        <span
                          className={styles.preCost}
                        >{`${currencySymbol}${round(
                          item.preCost * currencyRates,
                          2
                        )}`}</span>
                      </del>
                      <span
                        className={className(styles.costChange, {
                          [styles.costUp]: comparePrice > 0,
                          [styles.costDown]: comparePrice < 0
                        })}
                      >
                        (
                        {` ${currencySymbol}${round(
                          comparePrice * currencyRates,
                          2
                        )}`}
                        )
                        {comparePrice > 0 ? (
                          <i class="material-icons">trending_up</i>
                        ) : (
                          <i class="material-icons">trending_down</i>
                        )}
                      </span>
                      <span
                        className={styles.newCost}
                      >{`${currencySymbol}${round(
                        item.newCost * currencyRates,
                        2
                      )}`}</span>
                    </p>
                  ) : null}
                </div>
              </div>
            );
          })
      },
      {
        title: (
          <div>
            {'Shopify selling price'}{' '}
            <Tooltip title={intl.get('notification.shopPrice')}>
              <Icon type="question-circle" />
            </Tooltip>
          </div>
        ),
        align: 'center',
        dataIndex: 'shopifySellPrice',
        render: v => {
          return (
            <span>
              {' '}
              {currencySymbol} {v}
            </span>
          );
        }
      },
      {
        title: (
          <>
            <p className={styles.colTitle}>{intl.get('notification.price')}</p>
          </>
        ),
        align: 'center',
        dataIndex: 'price',
        render: (v, record) => (
          <InputNumber
            size="small"
            style={{ height: '32px', lineHeight: '32px' }}
            value={v}
            defaultValue={v}
            min={0}
            precision={2}
            formatter={value => `${currencySymbol}${value}`}
            parser={value => value.replace(`${currencySymbol}`, '')}
            onChange={e => this.changeSinglePrice(e, 'price', record.variantID)}
            // onBlur={e=>this.handleBlue(e,'price',record.variantID)}
          />
        )
      },
      {
        title: (
          <>
            <p className={styles.colTitle}>
              {intl.get('notification.compared_at_price')}{' '}
              <span style={{ marginLeft: 5 }}>
                <Tooltip title={intl.get('setting.pricing.price_no')}>
                  <Icon type="info-circle" />
                </Tooltip>
              </span>
            </p>
          </>
        ),
        align: 'center',
        dataIndex: 'compareAtPrice',
        render: (v, record) => (
          <InputNumber
            size="small"
            style={{ height: '32px', lineHeight: '32px' }}
            defaultValue={v}
            value={v}
            min={0}
            precision={2}
            formatter={value => `${currencySymbol}${value}`}
            parser={value => value.replace(`${currencySymbol}`, '')}
            onChange={e =>
              this.changeSinglePrice(e, 'compareAtPrice', record.variantID)
            }
            // onBlur={e=>this.handleBlue(e,'compareAtPrice',record.variantID)}
          />
        )
      }
    ];
  };

  // 改变单个variant的价格
  changeSinglePrice = (e, flag, variantID) => {
    // if (
    //   this.savePriceNumber.variantID !== variantID ||
    //   this.savePriceNumber.flag !== flag
    // ) {
    //   return;
    // }
    // let v = this.savePriceNumber.number;
    const { list } = this.state;

    const copyList = [...list];

    copyList.forEach(item => {
      if (item.variantID == variantID) {
        item[flag] = e;
      }
    });

    this.setState({
      list: copyList
    });
  };

  handleCancel = () => {
    this.hasGetShopifyProdDetail = true;
    this.storeHasRemoved = false;
    this.setState({
      list: []
    });
    this.props.handleCancel();
  };

  // 阻止冒泡
  stopPropagation = e => {
    e.stopPropagation();
    return false;
  };

  // 更新shopify 商品
  upDateToShopify = () => {
    this.props.dispatch({
      type: 'notification/updateShopifyPrice',
      payload: {
        data: this.getUpdateDate(),
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            message.success(intl.get('product.update_succ'));
          } else {
            message.error(d.msg);
          }
        }
      }
    });
  };

  // 获取提交保存价格的数据
  getUpdateDate = () => {
    const obj = {
      store_id: this.shopifyProductDetail.prod.store_id,
      shopify_product_id: this.shopifyProductDetail.prod.detail.id,
      variant_price: []
    };

    console.log(this.state.selectedRowKeys, 'this.state.selectedRowKeys');
    this.state.list.forEach(item => {
      if (this.state.selectedRowKeys.includes(item.variantID)) {
        obj.variant_price.push({
          variant_id: item.variantID,
          price: `${item.price}`,
          compare_at_price: `${item.compareAtPrice}`
        });
      }
    });

    return obj;
  };

  getTip = () => {
    console.log(this.props);
    if (this.storeHasRemoved) {
      return intl.get('notification.store_removed');
    } else if (this.shopifyDetailError == 'product id not found') {
      return intl.getHTML('notification.get_product_err');
    } else if (this.shopifyDetailError == 'product id not found to check') {
      return intl.getHTML('notification.get_product_err_2');
    } else if (this.shopifyDetailError == 'not support mapping type') {
      return intl.getHTML('notification.no_support_mapping');
    } else if (this.shopifyDetailError == 'sku or mapping error') {
      return intl.getHTML('notification.mapping_trouble');
    } else if (this.shopifyDetailError == 'sku changed') {
      return intl.getHTML('notification.product_sku_change');
    } else if (
      this.shopifyDetailError ==
      'The currency you set does not exist, please contact customer service'
    ) {
      return 'The currency you set does not exist, please contact customer service';
    } else if (this.hasChangeMapping) {
      return intl.get('notification.mapping_changed');
    } else {
      return intl.get('notification.product_deleted');
    }
  };

  onSelectChange = e => {
    this.setState({
      selectedRowKeys: e
    });
  };

  monetizationVisibleChange = v => {
    this.setState({
      monetizationVisible: v
    });
  };

  selectAll = e => {
    const { list } = this.state;
    let variantId = [];
    if (e.target.checked) {
      list.forEach(item => {
        variantId.push(item.variantID);
      });
    }
    this.setState({
      selectedRowKeys: variantId
    });
  };

  handleCopy = (e, title, id) => {
    if (this.ref.current) {
      clearTimeout(this.ref.current);
    }
    this.stopPropagation(e);

    const text = title;
    navigator.clipboard.writeText(text);

    this.setState({
      copySuccess: id
    });

    this.ref.current = setTimeout(() => {
      this.setState({
        copySuccess: ''
      });
    }, 1000);
  };

  renderStoreName = () => {
    let store = '';
    this.props.userInfo.stores.forEach(item => {
      if (item.id === this.storeId) {
        store = item.shop_name;
      }
    });
    return store;
  };

  render() {
    const {
      notificationItem,
      visible,
      detailLoading,
      updateLoading
    } = this.props;

    const { list, productid, selectedRowKeys, copySuccess } = this.state;
    if (!visible) {
      return null;
    }
    const shopifyProds = getShopifyProdItems(notificationItem);

    return (
      <Drawer
        title={
          <div className={styles.productChangeBox}>
            <h2>
              {getMsgByType(notificationItem.type)}
              <span>
                {intl.get('notification.at')}{' '}
                {this.toJSONLocal(notificationItem.createdat, true)}
              </span>
            </h2>
          </div>
        }
        placement="right"
        closable={false}
        width={868}
        onClose={this.handleCancel}
        visible={visible}
        wrapClassName={styles.productChange}
      >
        <div className={styles.productChangeBox}>
          <div className={styles.productCont}>
            <img
              src={replaceUrlToHttps(
                notificationItem.data.aliexpressprod.imagesrc
              )}
            />
            <div className={styles.productNews}>
              <p>
                {intl.get('notification.ali_product')}:
                <a
                  target="_blank"
                  href={getAliExpressUrl(
                    notificationItem.data.aliexpressprod.productid
                  )}
                >
                  {notificationItem.data.aliexpressprod.title}
                </a>
              </p>
              {this.state.supplyType ? (
                <span className={styles.supply}>{this.state.supplyType}</span>
              ) : null}
              <div className={styles.title}>
                {intl.get('notification.related_shopify_products')}{' '}
                {this.renderStoreName()}
              </div>
              <div className={styles.relatedShopify} id="positionarea1">
                <Select
                  defaultValue={shopifyProds[0].productid}
                  className={styles.shopifyPordSelect}
                  dropdownClassName={styles.notificationDropdownClassName}
                  // getPopupContainer={triggerNode => triggerNode.parentNode}
                  getPopupContainer={() =>
                    document.getElementById('positionarea1')
                  }
                  size="small"
                  onChange={this.changeShopifyProd}
                >
                  {shopifyProds.map(prod => {
                    return (
                      <Option key={prod.productid} value={prod.productid}>
                        <span
                          className={styles.shopifyProdName}
                          title={prod.title}
                        >
                          {prod.title}
                        </span>
                        <span
                          className={styles.copy}
                          onClick={e => {
                            this.handleCopy(e, prod.title, prod.productid);
                          }}
                        >
                          <Tooltip
                            title={
                              copySuccess !== prod.productid
                                ? intl.get('notification.copyTitle')
                                : intl.get('notification.copied')
                            }
                          >
                            {copySuccess !== prod.productid ? (
                              <img src={copy} alt="" />
                            ) : (
                              <img src={checked} alt="" />
                            )}
                          </Tooltip>
                        </span>
                      </Option>
                    );
                  })}
                </Select>
                <a
                  href={this.getShopifyProdLink(productid)}
                  target="_blank"
                  className={styles.linkToShopifyProduct}
                  onClick={this.stopPropagation}
                >
                  <i class="material-icons">open_in_new</i>
                </a>
              </div>
            </div>
          </div>
          {list.length || detailLoading ? (
            <>
              <div className={styles.selectCont}>
                <div style={{ marginLeft: '12px' }}>
                  <Checkbox
                    checked={
                      selectedRowKeys.length &&
                      selectedRowKeys.length === list.length
                    }
                    // indeterminate={Boolean(selectedRowKeys.length && selectedRowKeys.length !== list.length)}
                    onChange={this.selectAll}
                  >
                    {intl.get('product.selectAll')}
                  </Checkbox>
                </div>
                <div
                  className={styles.contDrop}
                  id="positionarea"
                  style={{ position: 'relative' }}
                >
                  <i class="material-icons">monetization_on</i>
                  <Dropdown
                    trigger="click"
                    overlay={this.menu()}
                    onVisibleChange={this.handleVisibleChange}
                    onMouseEnter={this.mouseEnterPrice}
                    visible={this.state.dropdownVisible}
                    getPopupContainer={() =>
                      document.getElementById('positionarea')
                    }
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={e => e.preventDefault()}
                    >
                      {intl.get('notification.price')}{' '}
                      <Icon type="caret-down" />
                    </a>
                  </Dropdown>
                  <div className={styles.division}>|</div>
                  <i class="material-icons">monetization_on</i>
                  <Dropdown
                    trigger="click"
                    overlay={this.menu()}
                    onVisibleChange={this.monetizationVisibleChange}
                    onMouseEnter={this.mouseEnterCompareAtPrice}
                    visible={this.state.monetizationVisible}
                    getPopupContainer={() =>
                      document.getElementById('positionarea')
                    }
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={e => e.preventDefault()}
                    >
                      {intl.get('notification.compared_at_price')}{' '}
                      <Icon type="caret-down" />
                    </a>
                  </Dropdown>
                </div>
              </div>
              <Table
                pagination={false}
                dataSource={list}
                columns={this.getColumns()}
                loading={detailLoading}
                rowKey="variantID"
                filterMultiple
                className={styles.changeTable}
                rowSelection={{
                  selectedRowKeys,
                  onChange: this.onSelectChange,
                  hideDefaultSelections: true,
                  onSelectAll: (elected, selectedRows, changeRows) =>
                    this.selectAll(elected, selectedRows, changeRows)
                }}
              />
            </>
          ) : (
            <InfoAlert text={this.getTip()} className={styles.InfoAlert} />
          )}

          <div className={styles.footerLine}>
            <Button onClick={this.handleCancel}>
              {intl.get('notification.got_it')}
            </Button>
            {list.length ? (
              <Button
                type="primary"
                loading={updateLoading}
                onClick={this.upDateToShopify}
              >
                {intl.get('notification.update_to_shopify')}
              </Button>
            ) : null}
          </div>
        </div>
      </Drawer>
    );
  }
}
