import React, { PureComponent, Fragment } from 'react';
import { connect } from 'dva';
import { Button, Modal, Icon, Select, message, Table, Alert, Drawer, Tooltip } from 'antd';
import intl from 'react-intl-universal';

import { fixedZero, getAliExpressUrl, replaceUrlToHttps } from '../../utils/utils';
import { getMsgByType, getShopifyProdItems } from './utils';
import { InfoAlert } from '../../components//Banner/OptimizerInfoTip';

import styles from './PriceChange.less';
import skuStyles from './SkuChange.less';

import foreignLink from '../../assets/foreign.svg';
import copy from 'assets/bargain/copy.png';
import checked from 'assets/bargain/check_circle.png';
import copyToClipboard from 'utils/copyToClipboard';

const Option = Select.Option;
const { confirm } = Modal;

/* eslint react/no-array-index-key: 0 */
@connect(({ notification, loading, login }) => ({
  notification,
  loading: loading.models.notification,
  userInfo: login.userInfo,
  listLoading: loading.effects['notification/list'],
  detailLoading: loading.effects['notification/shopifyProdDetail']
}))
export default class SkuChange extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      toolSelectValue: 1, // 批量修改价格中select的值
      productid: '',
      storeName:'',
      supplyType:'',
      copySuccess:''
    };
    this.ref = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    const { visible, notificationItem } = nextProps;

    visible &&
      this.hasGetShopifyProdDetail &&
      this.getShopifyProdDetail(
        getShopifyProdItems(notificationItem) && getShopifyProdItems(notificationItem)[0]?.productid,
        nextProps
      );
  }

  hasGetShopifyProdDetail = true; // 显示弹窗时有没有获取过商品详情
  shopifyProductDetail = null; // shopify product 商品详情
  changeWho = ''; // 批量修改谁的价格 price || compare at price
  changeAllValue = 0; // 批量修改输入的值
  hasChangeMapping = true; // shopify商品有没有更换过mapping商品 导致现存的ae商品消息数据 和 shopify商品mapping数据对不上
  is_pub = false;
  storeHasRemoved = false;
  shopifyDetailError = '';

  // 获取ae商品详情
  getShopifyProdDetail = (id, nextProps) => {
    const storeId = this.getStoreId(id, nextProps);

    this.setState({
      productid: id
    })

    if (!this.storesHasStoreId(storeId)) {
      this.storeHasRemoved = true;
      return;
    }

    this.renderStoreName(storeId)

    this.hasGetShopifyProdDetail = false;
    this.hasChangeMapping = true;
    this.shopifyDetailError = '';
    this.props.dispatch({
      type: 'notification/shopifyProdDetail',
      payload: {
        data: {
          id,
          store_id: this.getStoreId(id, nextProps)
        },
        callback: d => {
          if (d.code == 2000) {
            if (d.data == '') {
              this.hasChangeMapping = false;
            }
            this.shopifyProductDetail = d.data;
            if(d.data?.prod?.ext?.map_type === 1){
              if(d.data.prod.ext.basic[this.props.notificationItem?.data?.aliexpressprod?.productid]?.is_default){
                  this.setState({
                    supplyType:intl.get('notification.defaultSupplier')
                  })
              }else{
                this.setState({
                  supplyType:intl.get('notification.secondSupplier')
                })
              }
            }else{
              this.setState({
                supplyType:''
              })
            }
            this.setChangeDate();
            return;
          }else if(d.code === 5000 && d.msg){
            this.shopifyDetailError = d.msg
          }
          this.hasChangeMapping = false;
        }
      }
    });
  };

  // 用户的shopify店铺里有没有这个店铺
  storesHasStoreId = id => {
    const { stores = [] } = this.props.userInfo;

    return stores.some(store => store.id == id);
  };

  // 获取shopify商品所在的store id
  getStoreId = (shopifyPordid, props) => {
    const { notificationItem: { shopify_prods = {} } } = props;
    let v = '';

    Object.entries(shopify_prods).forEach(arr => {
      arr[1].forEach(item => {
        if (item.productid == shopifyPordid) {
          v = arr[0];
        }
      });
    });

    return v;
  };

  // 设置列表数据
  setChangeDate = () => {
    if (this.shopifyProductDetail == '') {
      this.setState({
        list: []
      });
      return;
    }
console.log(this.shopifyProductDetail,'this.shopifyProductDetail,,,,,');
    const {
      prod: { detail: { variants }, is_pub }
    } = this.shopifyProductDetail;

    const arr = [];

    variants.forEach(item => {
      const stockArr = this.getCostArr(item.id);

      if (stockArr.length == 0) {
        return;
      }

      arr.push({
        variantTitle: item.title,
        stockArr,
        variantID: item.id
      });
    });

    this.is_pub = is_pub;
    this.setState({
      list: arr
    });
  };

  // 获取shopify单个variant 对应的ae商品的sku数组
  getCostArr = variantId => {
    const arr = [];
    const skuIDs = this.getAeSkuID(variantId);

    skuIDs.forEach(sku => {
      const obj = {
        sku_desc: sku.sku_desc,
        sku: sku.sku,
        stock: this.getCost(sku.sku, 'newprod')
      };

      arr.push(obj);
    });

    return arr;
  };

  // 获取变换前后的库存
  getCost = (skuID, flag) => {
    // flag = preprod || newprod
    const { notificationItem: { data = {} } } = this.props;

    const prod = data[flag] || {};

    const { aeop_ae_product_s_k_us } = prod;
    let stock = null;
    // console.log(prod)
    if(prod.product_status_type !== 'onSelling'){
      return null;
    }
    if (aeop_ae_product_s_k_us && aeop_ae_product_s_k_us.aeop_ae_product_sku) {
      aeop_ae_product_s_k_us.aeop_ae_product_sku.forEach(item => {
        if (item.id == skuID) {
          item.s_k_u_available_stock && (stock = item.s_k_u_available_stock);
          !item.s_k_u_available_stock && item.sku_stock && (stock = 0);
        }
      });
    }

    return stock;
  };

  // 获取shopify product 某个 variant 对应的ae商品sku id
  getAeSkuID = variantId => {
    const { prod: { ext, ext: { map_type } } } = this.shopifyProductDetail;

    const {
      notificationItem: { data: { aliexpressprod: { productid } } }
    } = this.props;

    const mappingMap = {
      1: 'basic',
      2: 'advance',
      3: 'bogo',
      4: 'bundle',
      5: 'standard'
    };

    const map = ext[mappingMap[map_type]];
    const skuIDs = []; // null

    if (map_type == 1) {
      // const { supply = [] } = map[productid];

      const supply = map[productid]?.supply || []

      supply.forEach(item => {
        if (item.variant_id == variantId) {
          item.platform_product_id &&
            item.sku &&
            skuIDs.push({
              sku: item.sku
            });
        }
      });
    } else {
      const variantSp = map && map[variantId];
      variantSp &&
        variantSp.variant_supply.forEach(({ supply }) => {
          supply.forEach(item => {
            item.platform_product_id == productid &&
              item.sku &&
              skuIDs.push({
                sku_desc: item.sku_desc,
                sku: item.sku
              });
          });
        });
    }

    return skuIDs;
  };

  // 获取年月日时分
  timestampToTime = createdat => {
    const dateTime = new Date(createdat);
    dateTime.setMinutes(dateTime.getMinutes() - dateTime.getTimezoneOffset());

    const Y = dateTime.getFullYear();
    const M = fixedZero(dateTime.getMonth() + 1);
    const D = dateTime.getDate();
    const h = fixedZero(dateTime.getHours());
    const m = fixedZero(dateTime.getMinutes());

    return `${Y}-${M}-${D} ${h}:${m}`;
  };

  // 查看影响的另一个shopify商品
  changeShopifyProd = prodId => {
    this.setState({
      productid: prodId
    })
    this.getShopifyProdDetail(prodId, this.props);
  };

  // 获取shopify商品链接
  getShopifyProdLink = prodId => {
    const storeId = this.getStoreId(prodId, this.props);
    const stores = this.props.userInfo.stores || [];
    let domain = '';
    let storeName = '';

    console.log(stores,'stores');
    stores.forEach(item => {
      if (item.id == storeId) {
        domain = item.domain;
        storeName = item.shop_name;
      }
    });

    domain = domain.includes('myshopify.com')
      ? domain
      : `${storeName}.myshopify.com`;

    return `https://${domain}/admin/products/${prodId}`;
  };

  getColumns = () => {
    return [
      {
        title: intl.get('notification.sku'),
        dataIndex: 'variantTitle'
      },
      {
        title: intl.get('notification.stock'),
        dataIndex: 'stock',
        render: (v, record) =>
          record.stockArr.map(item => {
            if (item.stock === null) {
              return <span>{intl.get('notification.not_available')}</span>;
            }
            if (item.stock === 0) {
              return <span className={styles.stockIsZero}>0</span>;
            }
            if (item.stock > 0) {
              return (
                <div className={styles.stockIsAvailable}>{item.stock}</div>
              );
            }
          })
      }
    ];
  };

  // 关闭弹窗
  handleCancel = () => {
    this.hasGetShopifyProdDetail = true;
    this.storeHasRemoved = false;
    this.is_pub = false;
    this.setState({
      list: []
    });
    this.props.handleCancel();
  };

  // 阻止冒泡
  stopPropagation = e => {
    e.stopPropagation();
    return false;
  };

  // 下线商品
  putOffline = () => {
    confirm({
      title: intl.get('notification.offline_product'),
      okText: intl.get('public.confirm'),
      cancelText: intl.get('public.cancel'),
      onOk: () => {
        return new Promise((resolve, reject) => {
          const { prod: { id, store_id } } = this.shopifyProductDetail;

          this.props.dispatch({
            type: 'productmy/productStatus',
            payload: {
              data: {
                product_id: id,
                is_pub: false,
                store_id
              },
              callback: d => {
                if (d.code == 2000 || d.code == 2010) {
                  this.is_pub = false;
                }

                resolve();
              }
            }
          });
        }).catch(() => console.log('Oops errors!'));
      }
    });
  };

  getTip = () => {
    if (this.storeHasRemoved) {
      return intl.get('notification.store_removed');
    }else if(this.shopifyDetailError == 'product id not found'){
      return intl.getHTML('notification.get_product_err')
    }else if(this.shopifyDetailError == 'product id not found to check'){
      return intl.getHTML('notification.get_product_err_2')
    }else if(this.shopifyDetailError == 'not support mapping type'){
      return intl.getHTML('notification.no_support_mapping')
    }else if(this.shopifyDetailError == 'sku or mapping error'){
      return intl.getHTML('notification.mapping_trouble')
    }else if(this.shopifyDetailError == 'sku changed'){
      return intl.getHTML('notification.product_sku_change')
    }else if (this.hasChangeMapping) {
      return intl.get('notification.mapping_changed');
    } else {
      return intl.get('notification.product_deleted');
    }
  };

  renderStoreName = (id)=>{
    let store = ''
    this.props.userInfo.stores.forEach(item=>{
      if(item.id === id){
        store = item.shop_name
      }
    })
    this.setState({
      storeName:store
    })
  }

  handleCopy = (e,title,id)=>{
    if(this.ref.current){
      clearTimeout(this.ref.current)
    }
    this.stopPropagation(e)

    const text = title
    navigator.clipboard.writeText(text);

    this.setState({
      copySuccess:id
    })

   this.ref.current =  setTimeout(() => {
      this.setState({
        copySuccess:''
      })
    }, 1000);
}

  render() {
    const {
      notificationItem,
      visible,
      detailLoading,
      showMapping
    } = this.props;

    const { 
      list,
      productid,
      copySuccess
    } = this.state;

    if (!visible) {
      return null;
    }

    const shopifyProds = getShopifyProdItems(notificationItem);

    return (
      <Drawer
        visible={visible}
        footer={false}
        closable={false}
        onClose={this.handleCancel}
        placement="right"
        width={880}
        zIndex={999}
        wrapClassName={styles.productChange}
        title={
          <div className={styles.productChangeBox}>
            <h2>
              {getMsgByType(notificationItem.type)}
              <span>
                {intl.get('notification.at')}{' '}
                {this.timestampToTime(notificationItem.createdat)}
              </span>
            </h2>
          </div>
        }
      >
        <div className={styles.productChangeBox}>
          <div className={styles.productCont}>
            <img src={replaceUrlToHttps(notificationItem.data.aliexpressprod.imagesrc)} />
            <div className={styles.productNews}>
              <p>
                {intl.get('notification.ali_product')}:
                <a
                  target="_blank"
                  href={getAliExpressUrl(
                    notificationItem.data.aliexpressprod.productid
                  )}
                >
                  {notificationItem.data.aliexpressprod.title}
                </a>
              </p>
              {this.state.supplyType ? <span className={styles.supply}>
                {this.state.supplyType}
              </span> : null}
              <div className={styles.title}>
                {intl.get('notification.related_shopify_products')}  {this.state.storeName}
              </div>
              <div className={styles.relatedShopify}>
                <Select
                  defaultValue={shopifyProds[0].productid}
                  className={styles.shopifyPordSelect}
                  dropdownClassName={styles.notificationDropdownClassName}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  size="small"
                  onChange={this.changeShopifyProd}
                >
                  {shopifyProds.map(prod => {
                    return (
                      <Option key={prod.productid} value={prod.productid}>
                        <span
                          className={styles.shopifyProdName}
                          title={prod.title}
                        >
                          {prod.title}
                        </span>
                        <span className={styles.copy} onClick={(e)=>{this.handleCopy(e,prod.title,prod.productid)}}>
                            <Tooltip title={copySuccess !== prod.productid ? intl.get('notification.copyTitle') : intl.get('notification.copied')}>
                              {
                                copySuccess !== prod.productid ? 
                                <img src={copy} alt="" /> :
                                <img src={checked} alt="" />
                              }
                            </Tooltip>
                          </span>
                      </Option>
                    );
                  })}
                </Select>
                <a
                  href={this.getShopifyProdLink(productid)}
                  target="_blank"
                  className={styles.linkToShopifyProduct}
                  onClick={this.stopPropagation}
                >
                   <i class="material-icons">open_in_new</i>
                </a>
              </div>
            </div>
          </div>

          {list.length || detailLoading ? (
            <Table
              pagination={false}
              dataSource={list}
              columns={this.getColumns()}
              loading={detailLoading}
              rowKey="variantID"
              className={styles.changeTable}
            />
          ) : (
            <InfoAlert text={this.getTip()} className={styles.InfoAlert} />
          )}

          <div className={skuStyles.footerLine}>
            <Button
              type="primary"
              disabled={!this.is_pub}
              onClick={this.putOffline}
            >
              {intl.get('notification.put_offline')}
            </Button>
            <Button
              type="primary"
              disabled={!this.is_pub}
              onClick={e => showMapping(this.shopifyProductDetail)}
            >
              {intl.get('order.order_product.mapping')}
            </Button>
            <Button onClick={this.handleCancel}>
              {intl.get('notification.got_it')}
            </Button>
          </div>
        </div>
      </Drawer>
    );
  }
}
