/**
 * 复制文本到粘贴板
 *
 * @export
 * @param {*} text string
 */
export default function copyToClipboard(text) {
  if (!text) {
    return;
  }

  const el = document.createElement('textarea');

  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}
